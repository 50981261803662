import React from "react";

//import { Box, Link, Stack, Flex, Text  } from "@chakra-ui/react";

//import { FaBeer, FaAngry } from 'react-icons/fa';
//import { IoMdClose, IoMdMenu } from 'react-icons/io';

import { PageTitle, ThreeColumnTextBlock, FadeInBoxRight } from './Common.jsx';
//import { SinglePaneWithButton, TextHighlightAndButton, ThreeColumnTextBlock, ThreeIcon, FadeInBoxLeft } from './Common.jsx';

const threeColumnData = [
  {
    title: 'Who we are',
    bodyText: [
    "Ksf Dyslexia Consulting is a company that carries out diagnostic assessments for dyslexia and conducts tutoring for specific learning difficulties and study skills.",
    "The owner is Katherine Fisher.",
    "For the purpose of this policy, Katherine Fisher is the named Data Protection Officer/Controller and Head of Organisation.",
    "Ksf Dyslexia Consulting is committed to protecting the privacy of individuals and takes your privacy seriously. This GDPR Privacy Policy provides information about:",
    "1. Types of data collected",
    "2. How and when information is collected",
    "3. How collected data is stored and for how long",
    "4. Who the information is shared with including the limited conditions under which we may disclose it to others.",
    "5. Accessing personal information",


    ]
  },
  {
    title: '1. Types of data collected',
    bodyText: [
        "When a client contacts Ksf Dyslexia Consulting for a diagnostic assessment, a range of information is collected which enables the assessor to fulfil the service provision.",
        "All personal information will be processed in accordance with the General Data Protection Regulations (GDPR), May 2018. These regulations require registration with the Information ",
        "Commissioners Office (ICO). Ksf  Dyslexia Consulting is registered with the ICO.",
        "Type of information collected include:",
        "• Personal identifiers and biographical information, for example date of birth",
        "• Contact details – for example home address, email address and telephone number",
        "• Sensitive personal data – for example, details of why an individual seeks an assessment, details of a disability or specific learning difficulty, details of support a client/learner may have had in the past, assessment data, and notes written during and after consultation or assessment sessions or after other contact with client/learner.",
        "• Dates of meetings held with a client/learner.",
        "• Family Details – for example details of other family members with whom a client/learner or a client/learner’s school/college/workplace have given consent to contact.",
        "• Educational Information – for example information about school contacts with whom a client/ learner has given consent to contact. Information about a client’s education background and progress will be collected.",
        
    ]
  },
  {
    title: '2. How and why information is collected',
    bodyText: [
        "Information is collected in order to plan for and provide a diagnostic assessment or specialist support for an individual.",
        "This diagnostic assessment of specialist support has been requested by the client. This is the sole reason we collect information about a client or individual to be assessed.",
        "This is collected via email and telephone conversation in the form of a questionnaire in advance of the service provision, and by phone or face to face questioning during the assessment / tuition.",
        "Further clarification information by phone or email maybe requested after the assessment date.",
    ]
  },
  {
    title: '3. How collected data is stored and for how long.',
    bodyText: [
        "Ksf Dyslexia Consulting will hold personal data for as long as it is needed for us to provide the services or for the period required to retain this information by applicable UK tax law (currently 6 years).",
        "Test papers and accompanying information with responses will be stored in a secure place within the Kent Dyslexia premises to allow for assessment and report writing.",
        "These will be shredded or deleted within six weeks of the data of the assessment.",
        "The final report will be stored electronically and will be password encrypted for 6 years or until the assessed individual reaches 24 years of age, whichever is the longer.",
        "Invoices and financial records are kept in line with HMRC requirements for two years; after this time they will be shredded/destroyed.",  
    ]
  },
  {
    title: '4. Who the information is shared with including the limited conditions under which disclosure to others may occur.',
    bodyText: [
        "Once the assessment report is completed a password protected copy will be emailed to the person who has commissioned the assessment.",
        "The assessment will not be shared with a third party without written permission from the person who has requested the assessment.",
        "Email threads will be deleted regarding information provided for the assessment within 6 weeks of the assessment date.",
        "It is unlikely, but it may be necessary to share test papers with PATOSS as part of the Assessment Practicing Certificate Renewal Process. In this case, the information will be fully anonymised.",
        "All people working with children have a duty of care. This includes a legal requirement inform relevant authorities if they believe that a child is unsafe or being abused.",
        "In such an instance personal information will be shared as appropriate.",  
    ]
  },
  {
    title: '5. Accessing personal information',
    bodyText: [
        "You are entitled to view, amend, or delete the personal information that is held about you.",
        "Should you require a copy of your personal data; or for your personal data to be permanently deleted; or for changes/corrections made to it before this time, please notify me.",
        "Email your request to Katherine Fisher at katherine@ksfdyslexiaconsulting.co.uk",
        "Note: Requests from clients/earners to delete data relating to an individual will be considered an individual case-by-case basis but we may be unable to remove all records for legal reasons.",
    ]
  },
  {
    title: 'Reviewing the Privacy Notice.',
    bodyText: [
        "This is a working document and may be updated at any time in line with prevailing legislation and to reflect the growth of the business - and therefore should be revisited regularly to check for any updates. Ksf Dyslexia Consulting is fully committed to ensuring clients privacy and data protection rights.",
        "This policy was written on 20.09.2023 and will be reviewed as required according to legislation set out by the ICO.",  
    ]
  },
]




export default function Privacy () {

  return (
    <>
    <PageTitle>Privacy Statement</PageTitle>
    <FadeInBoxRight>
        <ThreeColumnTextBlock data={threeColumnData} mainTitle={null} />
    </FadeInBoxRight>
    </>
  )
};

/*
<SolidBlock bodyText={blockText} />
<Carousel data={CarouselContent} mainText={CarouselMainText} />
<TwoPaneWithButton data={TwoPaneContent} />
*/