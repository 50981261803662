import React from "react";
import { OrderedList, ListItem  } from "@chakra-ui/react";
//import { Box, Link, Stack, Flex, Text  } from "@chakra-ui/react";

//import { FaBeer, FaAngry } from 'react-icons/fa';
//import { IoMdClose, IoMdMenu } from 'react-icons/io';



export default function Definitions (props) {

  return (
    <OrderedList {...props}>
      <ListItem>this</ListItem>
      <ListItem>is</ListItem>
      <ListItem>the</ListItem>
      <ListItem>definitions page</ListItem>
    </OrderedList>
  )
};