import React from "react";
//import { Text, Flex } from "@chakra-ui/react";
//import { Box, Link, Stack, Flex, Text  } from "@chakra-ui/react";

//import { FaBeer, FaAngry } from 'react-icons/fa';
//import { IoMdClose, IoMdMenu } from 'react-icons/io';

import { PageTitle, ThreeColumnTextBlock, FadeInBoxRight } from './Common.jsx';
//import { SinglePaneWithButton, TextHighlightAndButton, ThreeColumnTextBlock, ThreeIcon, FadeInBoxLeft } from './Common.jsx';

const threeColumnData = [
  {
    title: 'Booking an assessment',
    bodyText: [
        "After an initial phone consultation, a diagnostic assessment can be booked. I will confirm the date and time by email.",
        "A non-refundable £150 deposit is required to secure the booking and will be deducted from the overall amount payable.",
    ]
  },
  {
    title: 'Prior to the assessment',
    bodyText: [
        "Prior to the assessment, I will send out a family questionnaire and a school questionnaire for completion at least one week before the assessment date. The school questionnaire must be completed by the class teacher with input from the SENDCo where appropriate.",
        "These are vital to the assessment process to fully understand your child’s learning strengths and challenges. Without them, a diagnostic decision cannot be made and the assessment report cannot be completed.",
        "It is essential that the student has had an eyesight check within the 12 months prior to assessment.",
        "Please let me know if glasses are prescribed between booking and completing the assessment. Glasses must be brought and worn during the assessment if required.",
    ]
  },
  {
    title: 'During an assessment',
    bodyText: [
        "The assessment process will take around 4 hours, including breaks for the student. If parents/carers feel the student would find this length of time difficult, the sessions can be split into 2. However, this must be discussed and booked prior to the assessment.",
        "I will act with professionalism during the assessment and similarly the student is required to behave in a manner that allows an accurate diagnosis. Where co-occurring needs mean that extra accommodations may be necessary, please discuss this with me before the assessment.",
        "I reserve the right to stop an assessment if the student is behaving inappropriately or becoming unduly upset by the process. When an assessment is taking place in the parent/carer’s house, a quiet room and table is required with very few interruptions.",
        "The parent/carer must be available at all times at the property but should not be present in the room where the assessment is taking place. ",
        "If the assessment is taking place at my house, the parent/guardian may leave the student in my care (I have an enhanced DBS) but must be contactable by phone at all times.",
    ]
  },
  {
    title: 'Post assessment',
    bodyText: [
        "The remaining payment of £425 must be made at least 24 hours before the date of the assessment.",
        "The report will explain the student’s learning strengths and difficulties and provide personalised recommendations for support at home and in school.",
        "It will also confirm whether a dyslexia diagnosis appropriate. A diagnosis is not always made. Once payment has been received, the report will be available within 14 working days from the date of assessment. In some circumstances further information must be gathered to support a diagnosis which delays the release of the report. If this is the case, I will contact and discuss this with you further.",
        "If requested, a further follow-up phone call can be arranged to discuss the findings and recommendations made in the report.",    
    ]
  },
  {
    title: 'Payment',
    bodyText: [
        "Diagnostic assessment for dyslexia is £575.",
        "A non-refundable deposit of £150 is required to secure the assessment date and time.",
        "A payment of £425 is due in full at least 24 hours before the assessment date.",
        "I will be unable to release a report until full payment has been made.",
    ]
  },
  {
    title: 'Cancellation Policy',
    bodyText: [
        "A diagnostic assessment may be cancelled prior to the appointment but the deposit is non-refundable.",
        "If I cancel the assessment for any reason such as illness, the deposit will be refunded in full and the assessment will be re-scheduled.",
        "If the student is unwell, please provide at least 24 hours notice and the assessment date will be re-arranged at the earliest possible convenience to both parties.",        
    ]
  },
  
]




export default function Terms () {

  return (
    <>
    <PageTitle>Terms And Conditions</PageTitle>
    <FadeInBoxRight>
        <ThreeColumnTextBlock data={threeColumnData} mainTitle={null} />
    </FadeInBoxRight>
    </>
  )
};

/*
<SolidBlock bodyText={blockText} />
<Carousel data={CarouselContent} mainText={CarouselMainText} />
<TwoPaneWithButton data={TwoPaneContent} />
*/