import React from "react";
import { Text, Flex  } from "@chakra-ui/react";
//import { Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel, Container  } from "@chakra-ui/react";
//import { Box, Link, Stack, Flex, Text  } from "@chakra-ui/react";

//import { FaBeer, FaAngry } from 'react-icons/fa';
//import { IoMdClose, IoMdMenu } from 'react-icons/io';
import { Card, PageTitle, SinglePaneWithButton, TextHighlightAndButton, LogoBanner,
    FadeInBoxLeft, FadeInBoxRight} from './Common.jsx';

import { BUFF }  from "../util/constants.js";

const panel1 = {
    image: 'KatherineHeadshot.jpg', link: '/contact', 
    buttonText: 'contact us', 
    bodyTitle: 'Background', 
    bodyText: [
        "I am an English teacher and qualified specialist Dyslexia teacher. I have spent the majority of my 20-year career teaching secondary English Language and Literature in state schools in Bristol, Gloucestershire and Wiltshire.",
        "I have taught many students over the years who had a diagnosis of dyslexia.",
        "I became interested in understanding how these students were able to develop successful strategies to support their learning.",
        "Also, I wanted to better understand how some students slipped through the net, with their dyslexia either misunderstood or undiagnosed.",
    ]
};

const panel2 = {
    image: 'bookStack.jpg', link: '/contact', 
    buttonText: 'contact us', 
    bodyTitle: "Experience", 
    bodyText: [
        "In recent years I have supported more students with additional needs in a SEN context and also provided specialist 1:1 intervention programmes for dyslexia students in both primary and secondary schools.", 
        "This led me to return to college to gain formal qualifications in this area.",
    ]
  };
  
  const textData = {
    link: '/contact', 
    buttonText: 'contact us', 
    title: 'My Education', 
    highlight: "Qualifications",
    bodyText: [
      'APC (Assessing Practicing Certificate)',
      'AMBDA (Associate member of the British Dyslexia Association)',
      'PG Cert SpLD / Dyslexia - Bath Spa University',
      'PG Cert English (secondary) Exeter University',
      'BA (hons) English',
    ]
  };

  const logoData = [
    { image: 'patossAPC.png', link: 'https://www.patoss-dyslexia.org/assessment-practising-certificate-apc'},
    { image: 'DyslexiaGuild.png', link: 'https://dyslexiaguild.org.uk/'},
    { image: 'patossPPM.png', link: 'https://www.patoss-dyslexia.org/Tutor-Index-Landing'},
    { image: 'DyslexiaSoc.jpg', link: 'https://www.bdadyslexia.org.uk/'},
  ]


export function About (props) {

  return (
    <>
    <PageTitle>About me</PageTitle>
    <FadeInBoxRight>
        <SinglePaneWithButton data={panel1} flipTextAndImage={false} useBackgroundColor={`${BUFF}`} hasButton={false}/>
    </FadeInBoxRight>
    <FadeInBoxLeft>
        <LogoBanner logoData={logoData} />
    </FadeInBoxLeft>
        
    <FadeInBoxRight>
        <SinglePaneWithButton data={panel2} flipTextAndImage={true} useBackgroundColor='cyan.600' hasButton={false}/>
    </FadeInBoxRight>
    <TextHighlightAndButton data={textData} useBackgroundColor='white' hasButton={false} />
    </>
  )
};

export function What (props) {

    return (
        <>
        <PageTitle>What do we do?</PageTitle>
        <Flex
            {...props}
        >
        <Card title='About Ksf Dyslexia Consulting' >
            <Text>At Ksf Dyslexia we offer a comprehensive diagnostic assessment for dyslexia in children and adults. We are AMBDA qualified and hold a current assessment practicing certificate (APC). We are qualified to assess individuals for Disabled Students' Allowance (DSA).</Text>
            <Text>Ksf Dyslexia Consulting offers specialist short-term intervention programmes for children diagnosed with dyslexia, which are devised to directly support the individual’s profile of strengths and weaknesses. </Text>
            <Text>In addition to this, Ksf Dyslexia Consulting offers specialised exam support programmes for GCSE and A-Level students who either have a diagnosis of dyslexia or simply require support with study skills. These programmes are 1:1, short-term, results-focused and are based on a profile of need identified by us. There is no requirement to have a formal assessment. </Text>
            <Text>You and your child may already have a diagnosis of dyslexia, may receive intervention from their school or you may suspect that your child has some unidentified additional needs. </Text>
            <Text>You may simply want support for yourself/ your child in understanding how to effectively study for exams. We use a research-based approach in line with current pedagogy in study skills. We offer 6- week blocks of bespoke study skills sessions which would ideally occur 4 to 6 months prior to exams. These are tailored to the individual and some screening assessments to measure areas of working, short term and long term memory, reading fluency and accuracy and writing speed would be undertaken.</Text>
            <Text> Ksf Dyslexia Consulting can also offer exam support in GCSE and A Level English Language and English Literature and A Level Film Studies.</Text>
        </Card>
      </Flex>
      </>
    )
  };


