import React from "react";
import { Text, Flex  } from "@chakra-ui/react";

import { Card, PageTitle } from './Common.jsx';

export default function Coaching (props) {

  return (
    <>
    <PageTitle>This is the Coaching page</PageTitle>
    <Flex
        minHeight='10xl'
        {...props}
    >
        <Card title='some content' >
            <Text>Some content goes in here which links to other pages</Text>
        </Card>

        <Card title='more content' >
            <Text>Some more content goes here which also links to other pages</Text>
        </Card>
    </Flex>
    </>
  )
};