import React from "react";

//import { Box, Link, Stack, Flex, Text  } from "@chakra-ui/react";

//import { FaBeer, FaAngry } from 'react-icons/fa';
//import { IoMdClose, IoMdMenu } from 'react-icons/io';

import { BUFF }  from "../util/constants.js";

import { SinglePaneWithButton, PageTitle, TextHighlightAndButton,
   ThreeColumnTextBlock, ThreeIcon, FadeInBoxRight, FadeInBoxLeft } from './Common.jsx';


const panel1 = {
  image: 'boyReadingOnBed.jpg', link: '/contact', 
  buttonText: 'contact us', 
  bodyTitle: "Want to find out more?", 
  bodyText: [
    "We'd love to hear from you and understand how we can support you and your child.",
    "Contact us to arrange a free phone consultation.",
  ]
};

const panel2 = {
    image: 'happyGirl.jpg', link: '/contact', 
    buttonText: 'contact us', 
    bodyTitle: "Want to find out more?", 
    bodyText: [
      "We'd love to hear from you and understand how we can support you and your child.",
      "Contact us to arrange a free phone consultation.",
    ]
  };

const textData = {
  link: '/contact', 
  buttonText: 'contact us', 
  title: 'Get support with exams and study when at university', 
  highlight: "Disabled Students' Allowance",
  bodyText: [
    'The access arrangements that a student has at GCSE and A Level will not continue into higher education, unless the individual has a diagnosis of a specific learning difficulty such as Dyslexia or Dyscalculia from a registered practitioner holding APC.',
    'To find out more about this please contact us or visit the government website (https://www.gov.uk/disabled-students-allowances-dsas)',
  ]
};

const threeColumnData = [
  {
    title: 'Free Consultation',
    bodyText: [
    "An initial phone conversation to help you understand if the diagnostic assessment may be right for your child",
    "We offer assessments to diagnose dyslexia from the age of 8 upwards into adulthood.",
    "Please contact us if your child is not yet 8 years old",
    "With the agreement of your child’s school SENDCo, part of the assessment report may be used to support access arrangements for exams. However, this must be discussed with the school prior to an assessment taking place.",
    ]
  },
  {
    title: 'The Assessment',
    bodyText: [
    "Need should be identified as early as possible by the school. It is best practice for a diagnostic assessment to take place after school intervention has not resulted in sufficient progress. This is because a lack of response to well-placed and appropriate intervention can be an important indicator of a specific learning disability.",
    "A full assessment to diagnose dyslexia uses a range of ‘best practice’ testing materials.",
    "The assessment process begins with collecting detailed background information regarding your child’s early language and literacy development. Your child’s school will be sent a questionnaire about your child’s educational history, progress, attainment and any already identified need.",
    "The assessment itself lasts approximately 4 hours, sometimes a little longer. This can be split into two sessions if required. Your child’s school should allow authorised absence for this. The assessor will ensure that your child feels comfortable and relaxed before beginning any of the assessments. ",
    "The assessment involves a range of different activities focusing on learning ability, memory, processing of sounds, along with reading, comprehension, spelling and writing. Observations and results from these assessments will be considered alongside background information from home and school. ",
    ]
  },
  {
    title: 'Reporting',
    bodyText: [
        "The assessor will come to a decision as to whether or not a diagnosis of dyslexia is appropriate. A detailed report will be produced by Ksf Dyslexia Consulting and a password protected digital copy sent to you within 14 days of the date (or latest date if split) of the assessment.", 
        "The report will explain your child’s assessment profile in terms of areas of strength and areas of need. It will contain personalised recommendations and action to support your child’s educational development.",
        "Where appropriate, it will also make suggestions for onward referrals to other professionals.",
    ]
  },
]

const threeIconData = [
  {
    title: 'Consult',
    image: 'ConsultIcon.png',
    bodyText: [
    "Free 30 minute initial chat",
    ]
  },
  {
    title: 'Assess',
    image: 'AssessIcon.png',
    bodyText: [
    "4 hour assessment",
    ]
  },
  {
    title: 'Report',
    image: 'ReportIcon.png',
    bodyText: [
      "Within 14 days of assessment completing",
      
    ]
  },
]


export default function Assessment (props) {

  return (
    <>
    <PageTitle>Assessment</PageTitle>
    <FadeInBoxRight>
        <ThreeIcon data={threeIconData}  mainTitle={null} />
    </FadeInBoxRight>
    <FadeInBoxLeft>
        <SinglePaneWithButton data={panel1} flipTextAndImage={false} useBackgroundColor={`${BUFF}`} hasButton={true}/>
    </FadeInBoxLeft>
    <ThreeColumnTextBlock data={threeColumnData} mainTitle='The Assessment Process in detail'/>
    <TextHighlightAndButton data={textData} useBackgroundColor='white' hasButton={true} />
    <SinglePaneWithButton data={panel2} flipTextAndImage={true} useBackgroundColor={`${BUFF}`} hasButton={true}/>

    </>
  )
};

/*
<SolidBlock bodyText={blockText} />
<Carousel data={CarouselContent} mainText={CarouselMainText} />
<TwoPaneWithButton data={TwoPaneContent} />
*/