import React from 'react';
import GlobalStyle from './theme/globalStyles.js';
import { ChakraProvider, Text, Grid, GridItem, extendTheme } from '@chakra-ui/react';
//import { Image, theme, Center, Box } from '@chakra-ui/react';
//import { Link, VStack, Grid } from '@chakra-ui/react';

import { Routes, Route, Outlet, useNavigate  } from "react-router-dom";
//import { ColorModeSwitcher } from './ColorModeSwitcher';

import { useToastHook } from "./components/Toast.jsx";

//import { Logo } from './Logo';

import { defaultUser, POST, BASE_URL } from "./util/constants.js";


import { LogoAndMenuOnly } from './components/NavBar.jsx';
import Footer from './components/Footer.jsx';
import { About, What} from './components/About.jsx';
import { Faqs} from './components/Faqs.jsx';
import Assessment from './components/Assessment.jsx';
import Contact from './components/Contact.jsx';
import Coaching from './components/Coaching.jsx';
import Logon from './components/Logon.jsx';
import Definitions from './components/Definitions.jsx';
import Home from './components/Home.jsx';
import Testamonials from './components/Testamonials.jsx';
import Tutoring from './components/Tutoring.jsx';
import Terms from './components/Terms.jsx';
import Privacy from './components/Privacy.jsx';


//--green: #345457;
//--gold: #e4bc7f;
//--blue: #c5fcff;



function App() {

  const [state, newToast] = useToastHook();
  const navigate = useNavigate()
  const [currentUser, setCurrentUser] = React.useState(defaultUser)

  const handleSignout = () => {

     
    const fetchData = {
      method: POST,
      headers: new Headers({
        'Content-Type': 'application/json; charset=UTF-8',
        'Accept': 'application/json'
  
      })
    }
  
    fetch(`${BASE_URL}logout/`, fetchData)
      .then( response=>{
        return ( response.json() )
      })
      .then( data=>{
        newToast({ message: `${currentUser.username} signed out`, status: "success" });
        setCurrentUser(defaultUser);
        window.sessionStorage.removeItem('accessToken');
        navigate('/home')
      })
      .catch((error) => {
        console.log("sign-out", error);
      })
  
  };


  const breakpoints = {
    base: "0px",  //0px
    sm: "580px",  //320px
    md: "760px",  //768px
    lg: "940px",  //992px
    xl: "1200px", //1280px
    "2xl": "1536px",  //1536px
  }

  const customTheme = extendTheme({breakpoints});

  return (
    <div style={{overflowY: 'scroll'}}>
    <ChakraProvider theme={customTheme} >
      <GlobalStyle />
      <Routes>
        <Route path="/" element={ <Layout /> } >
          <Route path='' element={<Home />} />
          <Route path='home' element={<Home />} />
          <Route path='about' element={<About/>} />
          <Route path='what' element={<What/>} />
          <Route path='assessment' element={<Assessment/>} />
          <Route path='coaching' element={<Coaching/>} />
          <Route path='faqs' element={<Faqs/>} />
          <Route path='definitions' element={<Definitions/>} />
          <Route path='tutoring' element={<Tutoring />} />
          <Route path='testamonials' element={<Testamonials />} />
          <Route path='logon' element={<Logon />} />
          <Route path='contact' element={<Contact />} />
          <Route path='terms' element={<Terms />} />
          <Route path='privacy' element={<Privacy />} />
          <Route path='*' element={<PageNotFound/>} />
        </Route>
      </Routes>
    </ChakraProvider>
    </div>
  );

}

const Layout = ( props ) => {

  return (
    <Grid
      minHeight={'100vh'}
      gridTemplateRows="auto 1fr auto"
      gridTemplateAreas={`
        "header"
        "main"
        "footer"`
      }
      {...props}
    >
      <GridItem
        width='100%'
        area={'header'}
      >
        <LogoAndMenuOnly />
      </GridItem>

      <GridItem
        area={'main'}
        justifySelf='center'
        width='100%'
      >
        <div style={{overflowY: 'hidden'}}>
            <Outlet  />
        </div>
      </GridItem>

      <GridItem width='100%' area={'footer'}>
        <Footer />
      </GridItem>

    </Grid>
  )
};
 

const PageNotFound = (props) => {
  return (
    <Text {...props}>
      Page not found.
    </Text>
  )
};

const Blank = (props) => {
  return (
    <Text p={20} {...props}>
      Under Construction.
    </Text>
  )
};

export default App;

//      border='3px solid green'
