import React from "react";
import { Center, Text, Box  } from "@chakra-ui/react";
import { Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel  } from "@chakra-ui/react";
//import { Box, Link, Stack, Flex, Text  } from "@chakra-ui/react";

//import { FaBeer, FaAngry } from 'react-icons/fa';
//import { IoMdClose, IoMdMenu } from 'react-icons/io';
import { FadeInBoxRight, PageTitle  } from './Common.jsx';



export function Faqs (props) {


const faqData = [
    {
        title: 'When should I get a dyslexia assessment?',
        text:[
            `In the first instance, if you have concerns about your child's progress in reading, writing or spelling, it is strongly suggested that you discuss these with their class teacher or school SENCo first.`,
            `They will be able to inform you of any additional support that is already in place or planned for your child.`,
            `If this has already occurred, they will be able to inform you of its effectiveness and your child's response to the intervention.`,
            `If your child does not respond to intervention and continues to have difficulties with their reading, writing or spelling, a dyslexia assessment may be considered as the next step.`,
            `Individuals with dyslexia tend to have an uneven assessment profile where there are clear strengths and weaker areas which require support.`,
            `However, there is no 'one size fits all' profile as each individual is different.`,
        ]
    },
    {
        title: 'What should I look out for?',
        text: [
            `Your child has found learning to read difficult and finds it hard to remember what they have read.`,
            `They may miss out words when reading, fail to recognise familiar words or add extra words.`,
            `They may find it difficult to identify the most important points from a passage.`,
            `Your child's writing contains persistent and inconsistent spelling mistakes where the same word is spelt differently in the same piece of work.`,
            `Your child finds identifying sounds in words or blending sounds together challenging, despite appropriate phonics training.`,
            `Your child confuses the order of letters in words when reading and/ or writing.`,
            `They may also  be confused by letters which look similar, such as b/d, p/g, p/q, n/u, m/w.`,
            `Your child's reading level and spelling ability do not reflect their verbal language ability.`,
            `So, they understand information when told verbally, but have difficulty with information that's written down.`,
        ]
    },
    {
        title: 'More common signs',
        text: [
            `Your child is working below the expected standard for their age in reading, writing or spelling and is making little or slow progress despite additional support.`,
            `Your child has participated in literacy interventions in school (for example phonics, reading, writing or spelling) but continues to find these areas difficult.`,
            `Your child has been identified as having a moderate to high likelihood of dyslexia on a screener.`,
            `Your child's teacher or SENCo has suggested they have dyslexic-type literacy difficulties and you would like to explore these further.`,
            `Your child may find it hard to carry out a sequence of instructions or may struggle to remember things in a sequential order, eg. time tables, days of the week, months of the year, the alphabet.`,
            `They may appear to have poor concentration.`,
            `A child who has a cluster of these difficulties together may be dyslexic.`,
            `However, people with dyslexia often have good skills in other areas, such as creative thinking and problem solving.`,
        ]
    },
    {
        title: 'How do I choose the right assessor for my child?',
        text: [
            `Specialist assessors and teachers should be AMBDA registered and have a current APC (Assessment Practicing Certificate).`,
            `This demonstrates that the assessor engages with high quality continuing professional development CPD).`,
            `This tells you that they are up to date with the latest research into dyslexia.`,
            `The relationship with the assessor is important. Look for one who will have a discussion with you prior to the assessment.`,
            `Make sure your child feels at ease with the assessor and is happy for an assessment to take place.`,
            `Do not be afraid to shop around. You should feel confident that the assessor will work flexibly to manage the needs of your child.`,
            `Not all diagnostic assessments result in a diagnosis of dyslexia. The assessor should discuss this possibility with you beforehand.`,
        ]
    },
    {
        title: 'Can you assess for other neuro-diverse conditions such as ADHD, Autism or Dyspraxia?',
        text: [
            `The simple answer is no! ADHD and Autism should only be diagnosed by either a fully qualified clinical psychologist or a paediatrician.`,
            `Dyspraxia can be diagnosed by a specialist occupational therapist. Your GP can make a referral for you.`,
            `Dyslexia assessors have knowledge and awareness of these conditions and may suggest an onward referral to an appropriate specialist.`,
            `This may be instead of a dyslexia diagnosis or alongside one.`,
        ]
    },
    {
        title: 'Does my child need a diagnosis?',
        text: [
            `For some people, having a diagnosis can change the way the perceive the difficulties they have with areas of literacy.`,
            `It can make them feel more positive and less defeated.`,
            `It can help them to understand where their strengths lie and how to better manage any areas of need.`,
            `While you do not need a diagnosis to get access arrangements for exams within the school system, you do need a diagnosis of a specific learning difficulty such as dyslexia in order to qualify for DSA (Disabled Students' Allowance) in higher education.`,
        ]
    },
    {
        title: `What will the school do with the assessment report if I am diagnosed with dyslexia?`,
        text: [
            `The school are not under any legal obligation to put in place the recommendations made in a diagnostic report from a qualified dyslexia assessor.`,
            `However, they should read the report and consider the information and recommendations as it gives them a wealth of information about your child's learning needs.`,
        ]
    },
    {
        title: 'Can my child get support in exams such as GCSEs or A Levels with a diagnosis of dyslexia?',
        text: [
            `A dyslexia diagnosis does not automatically mean that your child can qualify for access arrangements.`,
            `They may be able to use part of the dyslexia assessment for evidence.`,
            `However, the school should undertake their own screening for access arrangements and your child's teachers must indicate that any support or intervention given constitutes your child’s ‘normal way of working’.`,
            `This means that if your child need more time to complete tasks in class/ needs the questions or text to be read aloud to them, then this is the 'normal' way they work and therefore, they should be allowed extra time/ a reader in their exams.`,
            `A diagnosis of dyslexia should underpin all of this.`,
        ]
    },
    {
        title: 'Will my child automatically get the same access arrangements for exams at university if they received them at GCSE and A Level?',
        text: [
            `No. You must have a diagnosis of a specific learning difficulty such as dyslexia to gain DSA (Disabled Students' Allowance).`,
            `A DSA may bring the benefit of financial contributions towards laptops and other assistive technology. In addition, this may also allow your child to access a study support tutor, and gain extra time in exams and deadlines.`,
            `DSA is not means tested, but 'need' tested.`,
        ]
    },
    {
        title: 'What do I do if my child is not diagnosed as dyslexic?',
        text: [
            `The assessor will aim to ensure that you fully understand why a diagnosis of dyslexia is not appropriate at this time.`,
            `The assessor may feel that there are other conditions which may be affecting your child's progress and make an onwards referral.`,
        ]
    },
]

return (
    <>
    <PageTitle>Frequently Asked Questions</PageTitle>
    <Center>
    <FadeInBoxRight>
    
    <Accordion
        my={6}
        px={[2, 4, 8, 16]}
        allowToggle={true}
        allowMultiple={false}
        maxWidth={['100%', '90%', '2xl', '1200px']}
    >
    {
        faqData.map((item, index) => {
            return (

                <AccordionItem  key={index} >
                    <h2>
                    <AccordionButton fontSize={['0.9rem', '1.0rem', '1rem', '1rem']} _expanded={{ bg: 'cyan.600', color: 'white'}} >
                        <Box as="span" flex='1' textAlign='left'  >
                        {item.title}
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4} bg='cyan.50' fontSize={['0.8rem', '0.9rem', '1rem', '1rem']}>
                        {
                        item.text.map((para, secondIndex) => {
                            return (
                                <Text key={secondIndex} paddingTop='6px'>{para}</Text>
                            )
                        })
                        }
                    </AccordionPanel>
                </AccordionItem>

            )
        })

    }
    </Accordion>
    </FadeInBoxRight>
    </Center>
    </>
)

};
