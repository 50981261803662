import React from "react";
import { FormControl, FormErrorMessage, Stack, Textarea, VisuallyHidden, chakra, useBreakpointValue, 
  Image, Button, Box, Center } from "@chakra-ui/react";

//import { NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper } from "@chakra-ui/react";  
//import { Menu, MenuList, MenuItem  } from "@chakra-ui/react";  
//import { MenuButton as ChakraMenuButton  } from "@chakra-ui/react"; 

//import { ChevronDownIcon } from '@chakra-ui/icons'


import { FaEnvelope, FaPhone } from 'react-icons/fa';

import { FormLabel as ChakraFormLabel } from "@chakra-ui/react";
import { Input as ChakraInput } from "@chakra-ui/react";
import { Text as ChakraText } from "@chakra-ui/react";

import { BUFF }  from "../util/constants.js";

import { useToastHook } from "./Toast.jsx";
import { SlideDownHeading, FadeInBoxLeft, FadeInBoxRight, PageTitle } from './Common.jsx';

//import { FaBeer, FaAngry } from 'react-icons/fa';
//import { IoMdClose, IoMdMenu } from 'react-icons/io';

//import { restRequest } from "../util/utils.js";

import { POST, BASE_URL } from "../util/constants.js";

const SocialButton = ( {children, label, href, target} ) => {

    return (
        <chakra.button
            bg='blackAlpha.100'
            rounded={'full'}
            w={8}
            h={8}
            mr={4}
            cursor={'pointer'}
            href={href}
            target={target}
            display={'inline-flex'}
            alignItems={'center'}
            justifyContent={'center'}
            transition={'background 0.3s ease'}
            _hover={{
                bg: 'blackAlpha.400'
            }}
        >
            <VisuallyHidden>{label}</VisuallyHidden>
                {children}
        </chakra.button>
    );
};


const Text = (props) => {
  return (
    <ChakraText
      fontSize={['.8rem', '1rem']}
      p={4}
      color='cyan.800'
      {...props}
      >
      {props.children}
    </ChakraText>
  )
};

const Input = (props) => {
  return (
    <ChakraInput
      fontSize={['.8rem', '1rem']}
      maxW={'400px'}
      {...props}
      >
      {props.children}
    </ChakraInput>
  )
};

const FormLabel = (props) => {
  return (
    <ChakraFormLabel
      pt={2}
      fontSize={['.8rem', '1rem']} 
      color='cyan.800'
      {...props}
      >
      {props.children}
    </ChakraFormLabel>
  )
};


export default function Contact (props) {

  const [state, newToast] = useToastHook();
 

  const defaultBookingForm = {
    customer_name : '',
    customer_email:  '',
    customer_phone: '',
    description: '',
  };


  const [bookingForm, setBookingForm] = React.useState(defaultBookingForm)
  
  const defaultBookingFormError = {
    customer_name : false,
    customer_email:  false,
    customer_phone: false,
    description: false,
  };

  const [bookingFormError, setBookingFormError] = React.useState(defaultBookingFormError)

  const handleInputChange = (e) => {
    setBookingForm({...bookingForm, [e.target.name]: e.target.value})
    setBookingFormError(defaultBookingFormError)
  ;}
 
  
  const handleSubmit = () => {

    var isOverallError = false;
    var tempBookingFormError = {
      customer_name : false,
      customer_email:  false,
      customer_phone: false,
      description: false,
    };

    for (var field in bookingFormError) {
      if (bookingForm[field] === '') {
        tempBookingFormError[field] = true
        isOverallError = true;
      }
    }

    if (isOverallError) {
      setBookingFormError(tempBookingFormError)
      return
    };

    setBookingFormError(defaultBookingFormError)


    // newToast( { message: JSON.stringify(tempData), status: "success" } );

    const fetchData = {
      method: POST,        
      body: JSON.stringify(bookingForm),
      headers: new Headers({
        'Content-Type': 'application/json; charset=UTF-8',
        'Accept': 'application/json',
      })
    }

    var wasSuccessful = true;

    fetch(`${BASE_URL}enquiry/`, fetchData)
      
      .then( response=>{
        wasSuccessful = response.ok;
        return ( response.json() )
      })

      .then( data=>{
        
        if (wasSuccessful) {
          newToast({ message: "Katherine will be in touch very soon.", status: "success" });
          setBookingForm(defaultBookingForm);
      } else {
          newToast({ message: `${data.message}`, status: "warning" });
        }
      })
      
      .catch((error) => {
        newToast({ message: `Oops, something went wrong! ${error}`, status: "error" });
      })

  };

  return ( 
    <>
    <PageTitle>Contact us</PageTitle>
    <Center>
    <Stack direction={useBreakpointValue({base: 'column', sm: 'row', md: 'row', lg: 'row'})}
         bg='white' py={8} px={[2,8]}  spacing={20} align='center'
    >
        <FadeInBoxRight>
        <Stack direction ='column'>
            <Box align={useBreakpointValue({base: 'center', sm: 'right', md: 'right', lg: 'right'})} 
                fontSize={['1.2rem', '1.4rem']}
                color='cyan.700'
            >
                The best way to contact Ksf Dyslexia Consulting is by phone or email:
            </Box>
            <Box align={useBreakpointValue({base: 'center', sm: 'right', md: 'right', lg: 'right'})} 
                fontSize={['1.0rem', '1.2rem']}
            >
                <a href='mailto:katherine@ksfdyslexiaconsulting.co.uk' >
                <SocialButton><FaEnvelope/></SocialButton>
                katherine@ksfdyslexiaconsulting.co.uk</a>
            </Box>
            <Box align={useBreakpointValue({base: 'center', sm: 'right', md: 'right', lg: 'right'})} 
                fontSize={['1.0rem', '1.2rem']}
            >
                <SocialButton><FaPhone/></SocialButton>
                07905 945 301
            </Box>

        </Stack>
        </FadeInBoxRight>
        <FadeInBoxLeft>
            <Image width='150px' src='KatherineHeadshot.jpg' alt='image' />
        </FadeInBoxLeft>
    </Stack>
    </Center>
    
    <Box bg='white' py={8} pl={[2,8]}>

      <SlideDownHeading color='cyan.700' fontSize='1.2rem' >Alternatively, enter your details below, and we will be in touch</SlideDownHeading>

     
      <FadeInBoxRight>
        <Box p={2} m={2}>
        <FormControl isInvalid={bookingFormError.customer_name} isRequired>
          <FormLabel>Name</FormLabel>
            <Input name='customer_name' type='text' value={bookingForm.customer_name} onChange={handleInputChange} />
            {!bookingFormError.customer_name ? (
              null
            ) : (
              <FormErrorMessage>name is required.</FormErrorMessage>
            )}
          </FormControl>
          <FormControl  isInvalid={bookingFormError.customer_email} isRequired>
            <FormLabel>Email Address</FormLabel>
            <Input name='customer_email' type='email' value={bookingForm.customer_email} onChange={handleInputChange} />
            {!bookingFormError.customer_email ? (
              null
            ) : (
              <FormErrorMessage>email is required.</FormErrorMessage>
            )}
          </FormControl>
          <FormControl  isInvalid={bookingFormError.customer_phone} isRequired>
            <FormLabel>Phone Number</FormLabel>
            <Input name='customer_phone' type='tel' value={bookingForm.customer_phone} onChange={handleInputChange} />
            {!bookingFormError.customer_phone ? (
              null
            ) : (
              <FormErrorMessage>phone number is required.</FormErrorMessage>
            )}
          </FormControl>
            <FormControl  isInvalid={bookingFormError.description} isRequired>
          <FormLabel pb={0}  mb={0}>Description</FormLabel>
          <Text p={0} fontSize={'.8rem'} >please provide a brief description of your enquiry</Text>
            <Textarea
                name='description' type='text' height='120px' value={bookingForm.description}
                onChange={handleInputChange}  fontSize={['.8rem', '1rem']} maxW={'400px'}  />
            {!bookingFormError.description ? (
              null
            ) : (
              <FormErrorMessage>a description of your enquiry is required.</FormErrorMessage>
            )}      
          </FormControl>
          <Button
              isDisabled={false}
              onClick={handleSubmit}
              mt={4}
              colorScheme='cyan'
              type='submit'
            >
              Submit
            </Button>
          </Box>
        </FadeInBoxRight>
    
    </Box>
    </>
  )
};