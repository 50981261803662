import React from 'react';
import { Box, Flex, VStack, Image, Center, Text } from '@chakra-ui/react';
//import { Text, IconButton, Button, Stack, Collapse, Icon, Popover } from '@chakra-ui/react';
//import { PopoverTrigger, PopoverContent, useColorModeValue, useBreakpointValue, useDisclosure } from '@chakra-ui/react';    

//import { Menu, MenuButton, MenuList, MenuItem, MenuDivider, MenuGroup  } from '@chakra-ui/react'

//import { Link } from '@chakra-ui/react';
import { Link as ReactLink } from "react-router-dom";

import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
//import { ChevronDownIcon, ChevronRightIcon, } from '@chakra-ui/icons';
//import { BsPersonLinesFill } from 'react-icons/bs';
//import { defaultUser } from "../util/constants.js";

import { BUFF }  from "../util/constants.js";


const menuHeight = '80px';

export const LogoAndMenuOnly = () => {
    
    //const { isOpen, onToggle } = useDisclosure();
    const [ isOpen, setIsOpen ]  = React.useState(false);

    return (
        <Box
            boxShadow={`0 1px 3px 1px rgba(0, 0, 0, 0.4)`}
            backgroundImage={`linear-gradient(to right, cyan.300, cyan.500)`}
        >
        <Flex
            
            
            justifyContent={'space-between'}
            
            height={isOpen ? 'auto' : `${menuHeight}`}
        >
            <ReactLink to='/home' onClick={()=>{window.scroll(0,0)}}>
            
                <Box
                    height={'100%'}
                    textDecoration='none'
                    lineHeight={`${menuHeight}`}
                    px={'10px'}
                    color={`${BUFF}`}
                    fontFamily='Oooh Baby'
                    fontSize='1.5rem'
                >
                    <Image pt={3} height='66px' src='/ksfLogoCyanOnTransparent.png' alt='logo' 
                    />
                </Box>
            </ReactLink>

            <Flex
                display={{ base:'none', md: 'inline-flex' }}
                lineHeight={`${menuHeight}`}
                height={'100%'}
                pr={[0, 8]}
            >
                {NAV_ITEMS.map((navItem) => (
                    <NavMenuItem
                        key={navItem.label}
                        label={navItem.label}
                        link={navItem.href}
                        setIsOpen={setIsOpen}
                        myPadding={'0px'}
                    />
                ))}


            </Flex>



            <Center
                display={{ base:'inline-flex', md: 'none' }}
                lineHeight={`${menuHeight}`}
                height={`${menuHeight}`}
                pr={'30px'}
                cursor='pointer'
                opacity={'0.9'}
            >
                {
                isOpen ? 
                    <CloseIcon w={3} h={3} onClick={ () => {setIsOpen(false)} } />
                :
                    <HamburgerIcon w={5} h={5} onClick={ () => {setIsOpen(true)} } />
                }
            </Center>

        </Flex>
        <VStack
                spacing={'50px'}
                paddingBottom={'30px'}
                display={{base:(isOpen) ? 'static' : 'none', md:'none'}}
                width='130px'
                ml='auto' mr='auto'
                textAlign={'center'}
            >
                {NAV_ITEMS.map((navItem) => (
                    <NavMenuItem
                        key={navItem.label}
                        label={navItem.label}
                        link={navItem.href}
                        setIsOpen={setIsOpen}
                        myPadding={'10px'}
                    />
                ))}
            </VStack>
        </Box>
            
    );
}


const NavMenuItem = ( { label, link, setIsOpen, myPadding }, props ) => {

    return (
        <ReactLink
        to={link ?? '#'}
        textDecoration='none'
        onClick={()=>{window.scroll(0,0)}}
    >
        <Box
            fontSize='1.0rem'
            pr={'8px'}
            pl={'8px'}
            pb={myPadding}
            pos='relative'
            overflow='hidden'
            cursor='pointer'
            color={`${BUFF}`}
            onClick={ () => { setIsOpen(false) } }
            {...props}
            _before={{
                content: '""',
                pos: 'absolute',
                top: '95%',
                left: '-100%',
                width: '100%',
                height: '100%',
                bg: 'cyan.100',
                transition: 'transform .25s',
                transform: `translateX(0%)`,
            }}
            _hover={{
                bg: 'cyan.500',
                color: 'white',
                _before: {
                    transform: `translateX(100%)`,
                }
            }}
        >

                <Text>{label}</Text>
            
        </Box>
        </ReactLink>
    )
}


const NAV_ITEMS = [
    {
        label: 'home',
        href: '/home'
    },
    //{
    //    label: 'what we do',
    //    href: '/what'
    //},
    {
        label: 'about me',
        href: '/about'
    },
    {
        label: 'assessment',
        href: '/assessment'
    },
    {
        label: 'contact us',
        href: '/contact'
    },
    //{
    //    label: 'coaching',
    //    href: '/coaching'
    //},
    //{
    //    label: 'tutoring',
    //    href: '/tutoring'
    //},
    {
        label: 'FAQs',
        href: '/faqs'
    },
];