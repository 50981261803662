import React from "react";
//import { Text, Container, Spacer } from "@chakra-ui/react";

//import { Cards } from './Common.jsx';
import { PageTitle, SinglePaneWithButton, TextHighlightAndButton } from './Common.jsx';
import { Carousel, TwoPaneWithButton, SolidBlock } from './Common.jsx';
//import imogenAvatar from '../img/imogen.jpg';
//import tutoring from '../img/tutoring.jpg';
//import assessment from '../img/assessment.jpg';

const CarouselMainText = [
    'I am AMBDA qualified and hold a current assessment practicing certificate (APC).',
    'I also hold accredited teacher status (ATS) and am qualified to teach learners with specific learning difficulties and dyslexia.',
    'Ksf Dyslexia Consulting aims to give you a better understanding of:'
];
const CarouselContent = [
  {image: 'girlThinking.jpg', link: '', bodyTitle: "How to define dyslexia", bodyText: "We can help you understand the terminology used in definitions of dyslexia and how your child’s experience of specific learning difficulties relates to these."},
  {image: 'dyslexiaTiles.jpg', link: '', bodyTitle: "What dyslexia isn’t", bodyText: "We aim to dispel the myths surrounding dyslexia and leave you feeling informed and confident."},
  {image: 'magnifiedDiagnosis.jpg', link: '', bodyTitle: "What a diagnosis of dyslexia means", bodyText: "We will show you how a diagnosis of dyslexia fits within the various kinds of support and intervention available in a school, university or workplace setting."},
  {image: 'boyLearning.jpg', link: '', bodyTitle: "What happens during an assessment", bodyText: "We explain how your child will be assessed and how this information will be reported to you."},

];

const textData = {
  link: '/contact', 
  buttonText: 'contact us', 
  title: 'Assessment', 
  highlight: "Why get assessed?",
  bodyText: [
    "To give you and your child a better understanding of how they can learn most effectively and where their strengths and challenges lie.",
    "To help understand why school intervention hasn’t yet been successful in improving your child’s reading, spelling and / or writing. This will give the school more valuable information about your child’s learning needs.",
    "To support an application for access arrangements in exams to receive extra time, a reader, a scribe or to be able to use assistive technology such as a laptop.",
    "To support an application for Disabled Students' Allowance (DSA) when going onto higher education.",
    ]
};


// Must be 2 items 
const TwoPaneContent = [
  {image: 'kidsOnSeats.jpg', link: '/about', bodyTitle: "About me", bodyText: "Find out more about Katherine Fisher, your dyslexia assessor"},
  {image: 'manWriting.jpg', link: '/assessment', bodyTitle: "The assessment process", bodyText: "Find out more about what a diagnostic assessment for dyslexia involves."},
];

// 3 ideally

const cardContent = [
  {image: 'imogen.jpg', link: '/about', bodyTitle: 'About me', bodyText: 'find out about Katherine', buttonText: 'more >'},
  {image: 'tutoring.jpg', link: '/tutoring', bodyTitle: 'Tutoring', bodyText: 'discover tutoring opportunities', buttonText: 'more >'},
  {image: 'assessment.jpg', link: '/assessment', bodyTitle: 'Assessment', bodyText: 'how does assessment work', buttonText: 'more >'},
]

//const blockText = `At Ksf Dyslexia I offer comprehensive diagnostic assessments for dyslexia in children and adults.  I am able to assess individuals for Disabled Students' Allowance (DSA) when moving onto higher education.`;
const blockText = `At Ksf Dyslexia Consulting, we offer comprehensive diagnostic assessments for dyslexia in children up to the age of 18. We are also able to assess individuals for Disabled Students' Allowance (DSA) when moving onto higher education.`;


// Must be 1 item
const singlePaneContent = {
  image: 'lightbulbWorld.jpg',
  link: '/contact',
  buttonText: 'click here', 
  bodyTitle: "Want to find out more?", 
  bodyText: [
    "We'd love to hear from you and understand how we can support you and your child.",
    "Contact us to arrange a free phone consultation.",
]
};

export default function Home (props) {

  return (
    <>
    <PageTitle>Welcome to Ksf Dyslexia Consulting</PageTitle>
    <SolidBlock bodyText={blockText} />
    <Carousel data={CarouselContent} mainText={CarouselMainText} />
    <TextHighlightAndButton data={textData} useBackgroundColor='cyan.600' hasButton={false} />
    <TwoPaneWithButton data={TwoPaneContent} />
    <SinglePaneWithButton data={singlePaneContent} flipTextAndImage={true} useBackgroundColor='white'/>

    </>
  )
};

/*
<PageTitle>Welcome to Ksf Dyslexia Consulting</PageTitle>
<SolidBlock bodyText={blockText} />
<Carousel data={CarouselContent} mainText={CarouselMainText} />
<TwoPaneWithButtons data={TwoPaneContent} />
<SinglePaneWithButton data={singlePaneContent} />
<Cards data={cardContent} />
*/